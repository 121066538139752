import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material";
import Login from "./pages/Login";
import { AuthContext } from "./auth/AuthWrapper";
import { HomeContainer } from "./containers/HomeContainer";
import { PengaturanContainer } from "./containers/PengaturanContainer";
import { TransaksiContainer } from "./containers/TransaksiContainer";
import { GudangContainer } from "./containers/GudangContainer";
import { MonitoringContainer } from "./containers/MonitoringContainer";

function App() {
  const theme = createTheme({
    typography: {
      fontFamily: "Open sans, sans-serif",
    },
  });

  const { authState } = useContext(AuthContext);

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {authState.token && authState.user ? (
          <>
            <Route path="/beranda" element={<HomeContainer />} />
            <Route path="/pengaturan" element={<PengaturanContainer />} />
            <Route path="/transaksi" element={<TransaksiContainer />} />
            <Route path="/gudang" element={<GudangContainer />} />
            <Route path="/monitoring" element={<MonitoringContainer />} />
            <Route path="*" element={<Navigate to="/beranda" />} />
          </>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Navigate to="/beranda" />} />
          </>
        )}
        {/* <Route path="*" element={<Navigate to={authState.token && authState.user ? "/beranda" : "/login"} />} /> */}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
