import { useLazyQuery } from '@apollo/client'
import React, { createContext, useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { GET_SESSION } from '../graphql/services/Session'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const initialRender = useRef(true);

    const [authState, setAuthState] = useState({
        token: localStorage.getItem('token'),
        user: null
    })

    const [getUserSession, { loading, error, data }] = useLazyQuery(GET_SESSION, {
        onCompleted: (data) => {
            setAuthState(prevState => ({
                ...prevState,
                user: data.getSessionUser.result
            }))
        },
        onError: (error) => {
            navigate('/')
            console.log('error: ', error)
        }
    })

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            const token = localStorage.getItem('token');
            if (token) {
                getUserSession();
            }
        }
    }, [getUserSession, authState.user])

    const login = (token) => {
        localStorage.setItem('token', token);
        setAuthState((prevState) => ({
            ...prevState,
            token,
        }));
        getUserSession().then(() => {
            navigate('/beranda');
        })
    }

    const logout = () => {
        setAuthState({
            token: null,
            user: null
        })
        localStorage.removeItem('token')
        navigate('/')
    }

    return(
        <AuthContext.Provider value={{ authState, login, logout }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthProvider, AuthContext }